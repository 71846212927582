<template>
  <v-card>
    <v-card-title class="justify-space-between mb-0 py-7">
      <span class="text-h5"><strong> Chapter Wise Performance</strong></span>
      <!-- <vue-json-to-csv :json-data="csvListData" :labels="csvLabels" :csv-title="fileName"> -->
      <!-- <v-btn color="primary"> Export </v-btn> -->
      <!-- <v-btn color="primary" class="mr-3" :href="`https://api.stage.ebsr.in${this.FinancialReports}`" target="_blank"> Export </v-btn> -->
      <!-- </vue-json-to-csv> -->
    </v-card-title>
    <v-row class="ml-2 mr-2 mb-3">
      <!-- Start date template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="filter.date_from"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateFrom" label="Start Date : DD-MM-YYYY"  readonly hide-details
              outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_from" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(filter.date_from)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <!-- End data template -->
      <v-col cols="12" md="3">
        <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" :return-value.sync="filter.date_to"
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dateFormateTo" label="End Date : DD-MM-YYYY"  readonly hide-details
              outlined dense v-bind="attrs" v-on="on"></v-text-field>
          </template>
          <v-date-picker v-model="filter.date_to" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="$refs.menu1.save('')">Clear </v-btn>
            <v-btn text color="primary" @click="menu1 = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.menu1.save(filter.date_to)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>


      <v-col cols="12" md="6" class="pl-0">
        <v-btn color="primary" class="mr-2" @click="reportExport()"> Export </v-btn>
        <v-btn color="primary" @click="refreshButton()">Reset</v-btn>
        
      </v-col>

    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import financialService from '@/service/Reports.service'
import FileSaver from 'file-saver'
import VueJsonToCsv from 'vue-json-to-csv'
import { api } from '@/config/config'
import moment from 'moment'
const localStoragedata = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
const token = localStoragedata.token
export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      YearFrom: ['2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      YearTo: ['2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      aa: '',
      Donor_name: '',
      Tissue_no: '',
      listLoading: false,
      totallist: 0,
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      Reports: [],
      monthReports: [],
      filter: {
        date_from: '',
        date_to: ''
      },
      csvLabels: {
        center_name: { title: 'Center Name' },
        collection: { title: 'Collection' },

      },
      fileName: 'USER-LIST-' + moment().valueOf(),
      csvListData: [],

      headers: [
        { text: 'Cernter Name', value: 'center_name', width: '250px' },
        { text: 'Collection', value: 'collection', width: '250px' },

        // { text: 'Surgery Location', value: 'surgery_location', width: '150px' },
        // { text: 'Recipient Name', value: 'recipient_name', width: '150px' },
        // { text: 'Date of transplant', value: 'date_of_transplant', width: '150px' },
        //  { text: 'OPERATE', value: 'operate', width: '250px' },
      ],
    }
  },
  computed: {
    dateFormateTo() {
      return this.filter.date_to ? moment(this.filter.date_to).format('DD-MM-YYYY') : ''
    },

    dateFormateFrom() {
      return this.filter.date_from ? moment(this.filter.date_from).format('DD-MM-YYYY') : ''
    },
  },
  watch: {
    options: {
      handler() {

      },
      deep: true,
    },
  },


  methods: {
    refreshButton() {
      this.filter.date_from = '',
        this.filter.date_to = ''
    },


    async reportExport() {
      this.listLoading = true
      const data = {
        year_from: this.filter.date_from,
        year_to: this.filter.date_to,

      }

      if(this.filter.date_from == '' && this.filter.date_to == ''){
            this.snackbarText = 'Please Fill Date',
              this.snackbar = true
              return;
        }
        
      try {
        const response = await api.post(`reports/chapter_wise_performance_searching`, data, {
          headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        if (response) {
          this.snackbarText = response.data.message,
          this.snackbar = true
          this.Reports = response.data
          var value = `https://api.stage.ebsr.in${this.Reports.path}`
          FileSaver.saveAs(value, 'Chapter wise report');
          // console.log("response1",value)
        } else {
          this.donorReport = []
          this.totallist = 0
        }
        this.listLoading = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
